<template>
<div class="bg-gray-100">
  <!-- Section Hero -->
  <div class="bg-purple-100 py-14">
    <h3 class="text-2xl tracking-widest text-green-600 text-center">PRODUCT</h3>
    <h1 class="mt-8 text-center text-5xl text-green-600 font-bold">Our Product & Services Premium</h1>

    <!-- Box -->
    <div class="md:flex md:justify-center md:space-x-8 md:px-14">
      <!-- box-1 -->
      <div class="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
        <div class="w-sm">
          <img class="w-64" src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/a17abde8d83650a582a28432/users-with-speech-bubbles-vector_53876-82250.jpg" alt="" />
          <div class="mt-4 text-green-600 text-center">
            <h1 class="text-xl font-bold">Website Company</h1>
            <p class="mt-4 text-gray-600">Website Company merupakan website untuk kebutuhan sebuah UMKM atau perusahaan agar meyakinkan pelanggan bahwa perusahaan yang dibuat ada
              dan mudah ditemukan dalam pencarian.Tujuannya untuk meningkatkan kepercayaan pelanggan.
            </p>
            <p class="mt-2 mb-6">Mulai dari $90/10 pages</p>
            <a href="whatsapp://send?text=Hai,%20mau%20konsultasi%20?&phone=+6282245012207" class="mt-8 mb-4 py-2 px-14 rounded-full bg-green-600 text-white tracking-widest hover:bg-green-500 transition duration-200">MORE</a>
          </div>
        </div>
      </div>

      <!-- box-2 -->
      <div class="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
        <div class="w-sm">
          <img class="w-64" src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/3b242447f922540fbe750cab/fdf.jpg" alt="" />
          <div class="mt-4 text-green-600 text-center">
            <h1 class="text-xl font-bold">Desain UI/UX</h1>
            <p class="mt-4 text-gray-600">Rancangan Website berupa UI/UX yang dibuat berdasarkan alur bisnis untuk kebutuhan pengembangan aplikasi atau pembuatan aplikasi.
              Berguna untuk mengetahui experience pemakai sebelum dibuat, UI yang bisa dibuat support dengan baik untuk website,android,desktop maupun format Iphone. </p>
              <p class="mt-2 mb-6">Mulai dari $90/10 pages</p>
            <a href="whatsapp://send?text=Hai,%20mau%20konsultasi%20?&phone=+6282245012207" class="mt-8 mb-4 py-2 px-14 rounded-full bg-green-600 text-white tracking-widest hover:bg-green-500 transition duration-200">MORE</a>
          </div>
        </div>
      </div>

      <!-- box-3 -->
      <div class="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
        <div class="w-sm">
          <img class="w-64" src="https://images01.nicepage.com/c461c07a441a5d220e8feb1a/8cc47b39e719570b996d9879/dsds.jpg" alt="" />
          <div class="mt-4 text-green-600 text-center">
            <h1 class="text-xl font-bold">Application Website</h1>
            <p class="mt-4 text-gray-600">Aplikasi Website merupakan website yang digunakan untuk mempermudah pekerjaan dan dapat menyesuaikan kegunaannya.
              Website ini juga berguna untuk menyimpan data dan mempermudah monitoring sebuah sistem pekerjaan dan alur.
            </p>
            <p class="mt-2 mb-6">Mulai dari $150/app</p>
            <a href="whatsapp://send?text=Hai,%20mau%20konsultasi%20?&phone=+6282245012207" class="mt-8 mb-4 py-2 px-14 rounded-full bg-green-600 text-white tracking-widest hover:bg-green-500 transition duration-200">MORE</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
        components : {

        },
    }
</script>
